import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Tag from '~/components/Tag'
import { headerFontFamilyString, linesRem } from '~/utils/styling'
import { GetPostTagsQuery } from '~/types'

interface IFilterPanelProps {}

const allowedTags = [
  'проповеди',
  'слова',
  'новости',
  'уроки',
  'беседы',
  'стихи',
  'встречи',
  'телепередачи',
  'радиоэфир',
  'о сердечной молитве',
  'online',
  'ТВ-СПАС',
  'РАДИО-ВЕРА',
  'ТВ-СОЮЗ',
  'instagram',
  'ВК',
  'заметки-дня',
  'статьи',
  'духовная-телеграмма'
]

const FilterDiv = styled.div`
  background: #e2e2e2;
  border-radius: 5px;
  padding: 5px 10px 10px;
  width: 100%;
  .caption {
    color: #777;
    margin-bottom: ${linesRem(.25)};
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
`

const FilterPanel: React.FC<IFilterPanelProps> = () => {

  const data: GetPostTagsQuery = useStaticQuery(graphql`
    query getPostTags {
      allMarkdownRemark(filter: {frontmatter: {tags: {ne: null}}}) {
        edges {
          node {
            frontmatter {
              tags
            }
          }
        }
      }
    }
  `)

  const tags: {[key: string]: number} = {}
  for (const post of data.allMarkdownRemark.edges) {
    for (const tag of post.node.frontmatter.tags) {
      if (allowedTags.indexOf(tag) === -1) continue
      tags[tag] = tags[tag] ? tags[tag] + 1 : 1
    }
  }
  return (
    <FilterDiv className="fixed-width mb-3">
      <h4 className="caption">Фильтровать по тематике:</h4>
      <div className="list">
        {allowedTags.sort((a, b) => tags[b] - tags[a]).map((tag, index) => (
          <Tag label={tag} key={index} opacity={0.7}/>
        ))}
      </div>
    </FilterDiv>
  )
}

export default FilterPanel
